<template>
  <section class="Category">
    <Loader :loading="showLoader" />
    <div class="page-header mb-3">
      <h3 class="page-title">Add Brand</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/catalog/view-brand" class="text-primary">Brand
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add Brand</li>
        </ol>
      </nav>
    </div>
    <form class="pt-2" @submit.prevent="addBrand">
      <div class="row">
        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group  mb-1">
                <label class=" fs-12 p-0 m-0 col-form-label" for="name">Name
                </label>
                <div class="form-group">
                  <input type="text" v-model="add_brand.name" id="name" name="name" class="form-control" :class="{
                    'is-invalid': submitted && $v.add_brand.name.$error,
                  }" />
                  <div v-if="submitted && !$v.add_brand.name.required" class="invalid-feedback">
                    Name is required
                  </div>
                </div>
              </div>

              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Description
                  </label>
                  <div class="form-group">
                    <textarea class="form-control" rows="9" v-model="add_brand.description"></textarea>
                  </div>

                </div>
              </div>
              <div class="form-group  ">
                <label for=""> Status </label>
                <div class="form-group  ">
                  <toggle-button :value="true" v-model="add_brand.active"
                    :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">

              <div class="form-group  mb-1">
                <label class=" m-0 p-0 fs-12 col-form-label" for="name">Sort </label>

                <div class="form-group">
                  <input type="number" v-model="add_brand.sort" class="form-control" />
                </div>

              </div>
              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Image
                  </label>
                  <div class="form-group">
                    <div class="form-group">
                      <b-button v-b-modal.modal-xl class="choose-image">Choose Image</b-button>

                      <chooseImage @imageId="setImageId"></chooseImage>
                      <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions"
                        v-on:vdropzone-removed-file="removedFile" :useCustomSlot="true" v-on:vdropzone-success="success"
                        @vdropzone-sending="sendImages">
                        <div class="dropzone-custom-content">
                          <div class="dropzone-custom-content">
                            <div v-if="bannerImg">
                              <div class="image-size-dropzone">
                                <img :src="`${imageApiURL}/${bannerImg.thumb_s}`" class="show-img img-fluid" />
                              </div>
                              <div class="subtitle pt-1 pb-0">
                                <span> Change </span>
                                <span style="color: #eb2027"> Browse</span>
                              </div>
                            </div>
                            <div v-if="!bannerImg" class="pt-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                                fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-upload" color="">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                              <div class="subtitle">
                                <span> Drop File or </span>
                                <span style="color: #eb2027"> Browse</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </vue-dropzone>
                    </div>
                  </div>

                </div>
              </div>
              <div class="form-group  ">
                <label for=""> Show home </label>
                <div class="form-group  ">
                  <toggle-button :value="false" v-model="add_brand.show_home"
                    :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group float-right mt-3">
            <button class="btn btn btn-add btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </form>

  </section>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
import { catalogApi } from "../../../api";
import Loader from "../../../components/Loader";
import { required } from "vuelidate/lib/validators";
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
import chooseImage from "../../../components/chooseImage";
import axios from "axios";
export default {
  name: "add_brand",
  components: {
    Loader, chooseImage,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 10,
        addRemoveLinks: true,
      },

      add_brand: {
        active: true,
        show_home: false,
      },
      imageApiURL,
      category: '',
      submitted: false,
      showLoader: false,
      bannerImg: ""
    };
  },
  validations: {
    add_brand: {
      name: { required }
    },
  },
  methods: {
    removedFile(file) {
      console.log(file);
    },

    sendImages(file, xhr, formData) {
      let brands = "brands";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", brands);
    },
    setImageId(selectedId) {
      this.showLoader = true;
      axios.get(`${mediaApi}/files/${selectedId}`).then((response) => {
        this.bannerImg = response.data.data[0];
        this.showLoader = false;
      });
    },
    success(file, response) {
      this.add_brand.image_id = response.data.id;
      this.bannerImg = ""
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
    async addBrand() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.add_brand.active === true) {
        this.add_brand.active = 1;
      } else {
        this.add_brand.active = 0;
      }
      if (this.bannerImg) {
        this.add_brand.image_id = this.bannerImg.id
      }

      if (this.add_brand.show_home === true) {
        this.add_brand.show_home = 1;
      } else {
        this.add_brand.show_home = 0;
      }

      this.showLoader = true;
      const data = await catalogApi.addBrand({
        ...this.add_brand,
      });
      this.showLoader = false;
      this.$router.push("/catalog/view-brand");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>
<style scoped>
.dropzone {
  padding: 8px 20px !important;
}

.form-group label {
  text-align: right;
}

.form-group label span {
  font-size: 10px;
}
</style>